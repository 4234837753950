.services-container {
    justify-content: center;
    padding: 40px;
    background-image: radial-gradient(circle farthest-corner at 0% 0%, var(--primary-blue) -10%, rgb(220, 220, 220) 30.3%, rgb(159, 175, 255) 300%);
  }
  
  @media (max-width: 1200px) {
    .services-container {
      padding: 20px;
    }
  }
