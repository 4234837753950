.footer-container {
  background-color: var(--primary-blue);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  height: auto;
  display: flex;
  font-size: x-large;
  flex-direction: column;
  justify-content: center;
}

.footer-top {
  padding: 20px;
  margin: 20px;
}

.footer-top h2 {
  font-size: 26px;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 40px;
}

.footer-contact {
  display: flex;
  justify-content: space-around;
  margin: 0 10px;
  padding: 30px;
  /* border-top: 1px solid #ffffff; */
}

.footer-contact-item {
  text-align: left;
}

.footer-contact-item p {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.footer-contact-item a, .footer-contact-item span {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
}

.footer-bottom {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo{
  display:flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  /* margin: 20px; */
  gap:30px;
}

.footer-logo img {
  width: 60%;
  /* padding: 30px; */
}

.footer-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex: 1;
  text-align: left;
}

.footer-column {
  margin: 0 20px;
}

.footer-column h3 {
  font-size: 22px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.footer-column a {
  display: block;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.footer-column a:hover {
  color: #3498db;
}

.contact-group {
  margin-bottom: 20px;
}

.contact-group span {
  display: block;
  font-size: 20px;
  margin-bottom: 5px;
}

.footer-bottom-text {
  margin-top: 30px;
  font-size: 15px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.social-links a {
  color: #ffffff;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #3498db;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .footer-container {
    padding: 10px;
  }

  .footer-top {
    padding: 10px;
    margin: 10px;
  }

  .footer-contact {
    display: none;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin: 10px 0;
    text-align: center;
  }

.footer-logo{
  display: flex;
  width: 80%;
  height: 80%;
  gap:20px;
}

  .footer-top h2 {
    display: block;
    font-size: 22px;
    margin-bottom: 10px;
  }
}
