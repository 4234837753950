.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height:fit-content;
  text-align: center;
  display: none;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(246, 249, 252, 0.6);
}

.project-image-wrapper:hover .text-content {
  display: block;
}

.text-content h3 {
  font-size: 24px;
  margin: 0 0 10px;
}

.text-content .date {
  font-size: 18px;
  color: var(--primary-blue);
  width: auto;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--orange-color);
}

.text-content .description {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px;
}

.text-content .read-more {
  display: inline-block;
  padding: 5px;
  background: none;
  color: var(--primary-blue);
  text-decoration: none;
  border-bottom: 2px solid var(--orange-color);
}

.read-more:hover {
  transition: width 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .projects-container {
    padding: 20px;
  }
  .text-content {
    width: 100%;
    font-size: 0.8rem;
    padding: 40px;
  }

  .text-content h3 {
    font-size: 18px;
  }

  .text-content .date {
    font-size: 14px;
  }

  .text-content .description {
    font-size: 12px;
  }

  .text-content .read-more {
    font-size: 12px;
  }

  .add-project-form {
    padding: 15px;
  }

  .add-project-form input,
  .add-project-form textarea {
    font-size: 14px;
  }

  .add-project-form .area-inputs {
    flex-direction: column;
  }

  .add-project-form .scope-inputs input {
    width: 100%;
  }

  .add-project-form .scope-inputs button {
    width: 100%;
    margin-top: 10px;
  }

  .submit-project-button {
    font-size: 14px;
  }
}

add-project-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

.add-project-form input,
.add-project-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.add-project-form input[type="text"],
.add-project-form textarea {
  background-color: #f9f9f9;
}

.add-project-form input[type="text"]:focus,
.add-project-form textarea:focus {
  outline: none;
  border-color: var(--primary-blue);
}

.add-project-form .area-inputs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.add-project-form .area-inputs input {
  flex: 1;
}

.add-project-form .scope-inputs {
  margin-top: 20px;
}

.add-project-form .scope-inputs input {
  width: 80%;
  margin-right: 10px;
}

.add-project-form .scope-inputs button {
  padding: 8px 12px;
  border: none;
  background-color: var(--primary-blue);
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

.add-project-form .scope-inputs button:hover {
  background-color: var(--orange-color);
}

.add-project-form ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.add-project-form ul li {
  font-size: 14px;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.submit-project-button {
  padding: 10px 15px;
  border: none;
  background-color: var(--primary-blue);
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  align-self: center;
  margin-top: 20px;
}

.submit-project-button:hover {
  background-color: var(--orange-color);
}

.add-project-button {
  padding: 10px 20px;
  border: none;
  background-color: var(--primary-blue);
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 30px;
}

.add-project-button:hover {
  background-color: var(--orange-color);
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
  height: 100%;
  background-image: radial-gradient(circle farthest-corner at 80% 30%, var(--primary-blue) -10%, rgb(220, 220, 220) 30.3%, rgb(159, 175, 255) 300%);
}

.projects-splide {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
}

.unique-projects-splide .splide__arrow--prev {
  left: -2% !important;
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXAgPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXJpZ2h0Ij48cG9seWxpbmUgcG9pbnRzPSIxNSAxOCA5IDEyIDE1IDYiPjwvcG9seWxpbmU+PC9zdmc+');
}

.unique-projects-splide .splide__arrow--next {
  right: -2% !important;
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXAgPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXJpZ2h0Ij48cG9seWxpbmUgcG9pbnRzPSI5IDE4IDE1IDEyIDkgNiI+PC9wb2x5bGluZT48L3N2Zz4=');
}

.unique-projects-splide .splide__arrow {
  background-color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;
  border-radius: none;
}

.unique-projects-splide .splide__arrow::before {
  color: #fff !important;
}

.unique-projects-splide .splide__arrow:hover {
  background-color: #c9c9c9 !important;
  color: var(--primary-blue);
}

.projects-splide .splide__slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.projects-splide .splide__slide.is-active {
  transform: scale(1.3);
  opacity: 1;
}

.projects-title {
  margin-bottom: 100px;
  padding-bottom: 40px;
  justify-content: flex-start;
  align-self: flex-start;
}

.projects-splide .splide__slide:not(.is-active) {
  transform: scale(0.6);
  opacity: 0.5;
}

.projects-splide .splide__track {
  margin: auto;
}

.project-image-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.overlay {
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--primary-blue);
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
}

.project-image-wrapper:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
