.contact-us-container{
    display:flex;
    background-color: var(--primary-blue);
    flex-direction: column;
    width: 400px;
    height: 100%;
    /* margin: 20px; */
    padding: 20px;
}

.contact-us-container .button {
    background-color: var(--primary-blue); 
    color: white; 
    border: none; 
    border-bottom: 1px solid white; 
    padding: 10px; 
    cursor: pointer; 
    align-self: flex-end;
}
.contact-us-container h2 {  
    color:#ffffff;
    padding: 20px;
    text-align: center;
}

.contact-us-container form {
    display: flex;
    flex-direction: column;
}

.contact-us-container .buttons {
    margin-top: 50px;   
    align-self: flex-end;
}

.contact-us-container .buttons .button:hover {
    background-color: #ffffff; 
    color: var(--primary-blue); 
    border: none; 
    border-bottom: 1px solid var(--primary-blue); 
    padding: auto;
    cursor: pointer; 
    align-self: flex-end;
}

@media (max-width: 768px) {
    .contact-us-container{
        display:flex;
        background-color: var(--primary-blue);
        flex-direction: column;
        width: 300px;
        height: 100%;
        padding: 20px;
    }
    
}