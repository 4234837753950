/* CSS file */
@keyframes popUp {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.businesses-read {
    position: relative;
    background-image: radial-gradient(circle farthest-corner at 10% 0%,
            var(--primary-blue) -10%,
            rgb(220, 220, 220) 30.3%,
            rgb(159, 175, 255) 60%,
            rgb(191, 191, 214) 90%,
            var(--orange-color) 120%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 70px 20px;
    box-sizing: border-box;
}

.businesses-header {
    color: var(--primary-blue);
    margin: 10px;
}

.cardbusiness-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    width: 100%;
    text-align: center;
}

.cardbusiness {
    background: rgba(255, 255, 255, 0.9); 
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 2px #8ba1ab, 0 0 4px #8ba1ab, 0 0 6px #8ba1ab, 0 0 8px #8ba1ab;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #8ba1ab;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    mix-blend-mode: multiply;
    filter: opacity(0.8);
    animation: popUp 1s ease-in-out;
    flex-grow: 1; 
}

.cardbusiness:hover {
    transform: scale(1.05); 
    filter: none;
    cursor: pointer;
    backdrop-filter: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}

.readmore-button {
    padding: 8px 12px;
    background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.readmore-button:hover {
    background-color: var(--orange-color);
}

.cardbusiness-text h3 {
    margin-bottom: 30px;
    color: var(--primary-blue);
    animation: popUp 1.2s ease-in-out;
}

.cardbusiness-text h4 {
    color: var(--primary-blue);
    animation: popUp 1.2s ease-in-out;
}

.cardbusiness-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: popUp 1.3s ease-in-out;
}

.cardbusiness-text p {
    color: #555c7b;
    text-align: justify;
    padding: 10px 20px;
}

.cardbusiness-image {
    width: 200px;
    height: auto;
    border-radius: 10px;
    padding: 10px;
}

.back-link {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 8px 12px;
    background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.back-link:hover {
    background-color: var(--orange-color);
}

@media (max-width: 900px) {
    .businesses-read {
        height: auto;
        flex-direction: column;
    }

    .businesses-header {
        margin-top: 70px;
        margin-bottom: 10px;
    }

    .cardbusiness-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .cardbusiness {
        padding: 20px;
        margin: 20px;
        width: 100%;
        max-width: 400px;
    }

    .cardbusiness-image {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}
