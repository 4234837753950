.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
  background-color: #ffffff;
}

.about-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 40px;
  justify-content: center;
  width: 100%;
}

.about-title {
  margin-bottom: 20px;
}

.about-image-container {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.background-design {
  position: absolute;
  top: -25px;
  left: -25px;
  width: calc(100% - 35px);
  height: calc(100% - 35px);
  background: radial-gradient(circle, var(--primary-blue) 10%, transparent 20%) 0 0,
    radial-gradient(circle, var(--primary-blue) 10%, transparent 20%) 40px 40px;
  background-size: 40px 40px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.7s ease 0.7s;
}

.animated-image {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  max-height: 100vh;
  left: 20px;
  top: 20px;
  border-radius: 8px;
  transform: translateX(-100%);
  transition: transform 1s ease;
}

.about-image-container.animate .animated-image {
  transform: translateX(0);
}

.about-image-container.animate .background-design {
  opacity: 1;
}

.about-details-container {
  width: 50%;
  padding: 50px;
  margin: 40px;
  text-align: justify;
}

.about-details-container h2 {
  font-size: 2.3em;
  margin-bottom: 20px;
  color: var(--primary-blue);
}

.about-span {
  display: block;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #7f8c8d;
  width: 60%;
}

.about-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.about-list-item {
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
}

.about-icon {
  color: var(--primary-blue);
  font-size: 1.5em;
  margin-right: 10px;
  padding: 5px;
  position: relative;
}

.about-button {
  padding: 10px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  border: 1px solid var(--primary-blue);
  background-color: var(--primary-blue);
  color: #ffffff;
  text-decoration: none;
}

.about-button:hover {
  background-color: #ffffff;
  color: var(--primary-blue);
  text-decoration: none;
}

@media (max-width: 1200px) {
  .about-content {
    flex-direction: column;
  }

  .about-image-container,
  .about-details-container {
    width: 100%;
    margin: 20px 0;
  }

  .animated-image {
    /* height */
    left: 20px;
    top: 20px;
    transform: translateX(-100%);
    transition: transform 1s ease;
  }

  .about-span {
    font-size: medium;
    width: 100%;
  }

  .about-details-container {
    padding: 20px;
    margin-left: 0;
  }

  .about-list {
    font-size: small;
  }


  .about-button {
    padding: 10px 20px;
    font-size: small;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid var(--primary-blue);
    background-color: var(--primary-blue);
    color: #ffffff;
    text-decoration: none;
  }

  .about-button:hover {
    background-color: #ffffff;
    color: var(--primary-blue);
    text-decoration: none;
  }

}