@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.banner-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: radial-gradient(circle farthest-corner at 0% 0%, var(--primary-blue) -10%, rgb(200,200,224)  30.3%,rgb(200, 200, 224),  90%,var(--primary-blue) 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 6s ease infinite;
  padding: 40px; 
  width: 80%; 
  margin: 50px auto; 
  margin-bottom: 60px;
  color: var(--primary-blue);
  opacity: 0;
  transition: opacity 1s ease-in-out; 
}

.banner-container.fade-in {
  opacity: 1;
}

.text-section {
  flex: 1;
  padding: 20px;
  text-align: center; /* Center the text */
  font-size: 3rem; /* Increase font size for emphasis */
}

.animated-text {
  display: inline-block;
}

.letter {
  display: inline-block;
  opacity: 0;
  animation: fadeInLetter 0.5s forwards;
}

@keyframes fadeInLetter {
  to {
    opacity: 1;
  }
}

.contact-button {
  position: absolute;
  right: 40px;
  bottom: -30px;
  background-color: var(--orange-color);
  color: #ffffff;
  border: 1px solid var(--orange-color);
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.1rem;
}

.contact-button:hover {
  background-color: #ffffff;
  color: var(--orange-color);
  border: 1px solid var(--orange-color);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .banner-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .text-section {
    width: 100%;
    padding: 10px;
    font-size: 2rem;
  }

  .contact-button {
    position: static;
    margin-top: 20px;
  }
}

