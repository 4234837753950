.advertisers-service-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 0;
}

.advertisers-service-sec .container {
  width: 100%;
}

.advertisers-service-sec .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.advertisers-service-sec .col {
  padding: 0 1em 1em 1em;
  text-align: center;
}

.advertisers-service-sec .service-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.7s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) -10%, rgb(225, 225, 225) 30.3%, rgb(159, 175, 255) 300%);
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.7s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {
  background-color: #ffffff;
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: #0dcaf0;
}

.advertisers-service-sec .service-card:hover h3 {
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover .card-description {
  display: block;
  text-align: justify;
}

.card-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.card-image-description .card-image img {
  width: 100%;
  height: auto;
  display: block;
}

.card-image-only img {
  /* width: 100%; */
  /* height: auto; */
  display: block;
}

.card-image-description .card-description {
  display: none;
}

.advertisers-service-sec .service-card:hover .card-image-description .card-description {
  display: block;
}

.card-image-wrapper {
  position: relative;
  display: inline-block;
}

.card-image {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 15px;
  /* position: relative; */
  z-index: 1;
}

.card-image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* Match this to the border-radius of the image if using */
  background: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) -10%, rgb(225, 225, 225) 30.3%, rgba(159, 175, 255, 0) 300%);
  mix-blend-mode: multiply;
  /* Choose the blend mode you prefer */
  pointer-events: none;
  z-index: 2;
}

.card-image-only {
  position: relative;
  display: inline-block;
}

.card-image-only img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 15px;
  /* Optional: gives the image rounded corners */
  position: relative;
  z-index: 1;
}

.card-image-only::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* Match this to the border-radius of the image if using */
  background: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) -10%, rgb(225, 225, 225) 30.3%, rgba(159, 175, 255, 0) 300%);
  mix-blend-mode: multiply;
  /* Choose the blend mode you prefer */
  pointer-events: none;
  z-index: 2;
}

.advertisers-service-sec .service-card:hover .card-image-description .card-image {
  display: none;
}

.description-list {
  padding: 0;
  list-style-type: none;
  text-align: center;
  font-size: 18px;
}

.request-service-card {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  /* Added to enable absolute positioning */
  z-index: 2;
}

.request-service-button {
  background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
  color: #fff;
  border: none;
  padding: 0.4em;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border-radius: 5px;
  transition: background-color 0.3s;
  position: absolute;
  top: -15px;
  /* Adjust as needed to position the button */
  left: 50%;
  transform: translateX(-50%);
}

.request-service-button:hover {
  background-color: #1a2856;
}

.description-item {
  position: relative;
  padding-left: 1.5em;
  text-align: justify;
  color: var(--primary-blue);
  font-size: 16px;
  line-height: 1.8;
}

.description-item::before {
  content: "-";
  position: relative;
  left: -0.5em;
  top: 0;
  font-size: 1.6rem;
  color: #1f194c;
}

/* Counter for the list items */
.description-list {
  counter-reset: item;
}

/* Service Card Responsive Styles */

/* For medium screens (tablets) */
@media (max-width: 992px) {
  .advertisers-service-sec .row {
    grid-template-columns: repeat(2, 1fr);
  }

  .advertisers-service-sec h3 {
    font-size: 18px;
  }

  .advertisers-service-sec p {
    font-size: 14px;
  }

  .subservice-content.visible {
    padding: 0;
  }

  .description-list {
    font-size: 16px;
  }

  .description-item {
    font-size: 14px;
  }

  .request-service-button {
    font-size: 0.875em;
  }

  .advertisers-service-sec .col {
    padding: 0;
  }

  .row {
    padding: 0;
    margin:0;
  }
}

/* For small screens (phones) */
@media (max-width: 576px) {
  .advertisers-service-sec .row {
    grid-template-columns: 1fr;
  }

  .advertisers-service-sec h3 {
    font-size: 16px;
  }

  .advertisers-service-sec p {
    font-size: 12px;
  }

  .description-list {
    font-size: 14px;
  }

  .subservice-content.visible {
    padding: 0;
  }

  .description-item {
    font-size: 12px;
  }

  .row {
    padding: 0;
    margin:0;
  }

  .request-service-button {
    font-size: 0.75em;
  }

  .service-card {
    /* padding: 1em; */
    width: 100%;
  }

  .advertisers-service-sec .col {
    padding: 0;
  }

}