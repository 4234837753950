@keyframes popUp {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.aboutus-read {
    position: relative;
    background-image: radial-gradient(circle farthest-corner at 10% 0%,
            var(--primary-blue) -10%,
            rgb(220, 220, 220) 30.3%,
            rgb(159, 175, 255) 60%,
            rgb(191, 191, 214) 90%,
            var(--orange-color) 120%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}

.aboutus-image {
    max-width: 300px;
    max-height: 200px;
    display: block;
    margin: 15px auto;
    animation: popUp 1s ease-in-out;
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.card {
    position: relative;
    background: rgba(255, 255, 255, 0.9); 
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 2px #8ba1ab, 0 0 4px #8ba1ab, 0 0 6px #8ba1ab, 0 0 8px #8ba1ab;
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #8ba1ab;
    height: 100%; 
    max-height: 100%;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    mix-blend-mode: multiply;
    filter: opacity(0.8);
    animation: popUp 1s ease-in-out;
}

.card:hover {
    transform: scale(1.1); 
    filter: none;
    cursor: pointer;
    backdrop-filter: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}

.card h2 {
    margin-top: 20px;
    color: var(--primary-blue);
    animation: popUp 1.2s ease-in-out;
}

.card-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: popUp 1.3s ease-in-out;
}

.card-text p {
    color: #555c7b;
    text-align: justify;
    padding: 10px 20px;
}

.back-link {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 8px 12px;
    background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .back-link:hover {
    background-color: var(--orange-color);
  }


  @media (max-width:1000px) {
    .aboutus-read {
        position: relative;
        background-image: radial-gradient(circle farthest-corner at 10% 0%,
                var(--primary-blue) -10%,
                rgb(220, 220, 220) 30.3%,
                rgb(159, 175, 255) 60%,
                rgb(191, 191, 214) 90%,
                var(--orange-color) 120%);
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    .aboutus-image {
        max-width: 300px;
        max-height: 200px;
        display: block;
        margin: 15px auto;
        animation: popUp 1s ease-in-out;
    }
    
    .card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
    
    .card {
        position: relative;
        background: rgba(255, 255, 255, 0.9); 
        padding: 20px;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 0 2px #8ba1ab, 0 0 4px #8ba1ab, 0 0 6px #8ba1ab, 0 0 8px #8ba1ab;
        width: 100%;
        max-width: 300px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #8ba1ab;
        height: 100%; 
        max-height: 100%;
        transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
        mix-blend-mode: multiply;
        filter: opacity(0.8);
        animation: popUp 1s ease-in-out;
    }
    
    .card:hover {
        transform: scale(1.1); 
        filter: none;
        cursor: pointer;
        backdrop-filter: none;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
    }
    
    .card h2 {
        margin-top: 20px;
        color: var(--primary-blue);
        animation: popUp 1.2s ease-in-out;
    }
    
    .card-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation: popUp 1.3s ease-in-out;
    }
    
    .card-text p {
        color: #555c7b;
        text-align: justify;
        padding: 20px;
    }
    .back-link {
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 8px 12px;
        background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
        color: white;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.3s ease-in-out;
      }
      
      .back-link:hover {
        background-color: var(--orange-color);
      }
    
  }