.title-container {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 40px; */
    /* padding-bottom: 20px; */
    width:100%;
}

.orange-line {
    height: 7px;
    width: 80px;
    background: linear-gradient(to right, #FFB876,var(--orange-color));
}

.title {
    font-size:var(--size);
    font-weight: bold;
    margin: 10px 0;
    color: var(--primary-blue);
    display: flex;
    text-transform: uppercase;
}

