:root {
  --primary-blue: #263870;
  --secondary-blue: #285e9f;
  --third-blue: #285791;
  --orange-color: #FF7B00;
  --size: 30px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;;
}
*{
  margin: 0;
  padding: 0;
}
  
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

