.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  max-width: 100%;
  color: var(--primary-blue);
  font-weight: bolder;
  margin-top: 50px;
  opacity: 0; 
  transform: translateX(0); 
  transition: transform 1s ease, opacity 1s ease; 
}

.counter-container.slide-in {
  opacity: 1; 
  transform: translateX(0); /* Slide in */
}

.counter-title {
  font-size: 2.4em;
  text-align: center;
  margin-bottom: 40px;
  color: var(--primary-blue);
}

.counter-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.counter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.2em;
  color: var(--orange-color);
}

.counter-value {
  display: flex;
  align-items: baseline;
}

.counter-item p {
  margin-top: 10px;
  font-size: 1em;
  color: #999999;
}

.postfix {
  margin-left: 5px;
}

.vertical-line {
  height: 40px;
  width: 4px;
  background-color: #bdc3c7;
  margin: 0 20px;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .counter-items {
    flex-direction: column;
    align-items: center;
  }

  .vertical-line {
    display: none; 
  }

  .counter-item {
    margin-bottom: 20px; 
  }
  
.counter-title {
  font-size: 2.1em; 
}
}
