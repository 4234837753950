.project-details-container {
  position: relative;
  background-image: radial-gradient(
    circle farthest-corner at 10% 0%, 
    var(--primary-blue) -10%, 
    rgb(220, 220, 220) 30.3%, 
    rgb(159, 175, 255) 60%, 
    rgb(191, 191, 214) 90%, 
    var(--orange-color) 120%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-title-back-link {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 20px;
}

.project-titlee {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}

.project-titlee h2 {
  color: var(--primary-blue);
}

.project-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  z-index: 2;
}

.back-link {
  padding: 8px 12px;
  background-image: radial-gradient(circle farthest-corner at 20% 100%, var(--primary-blue) 10%, rgb(123, 136, 154) 80.3%, rgb(80, 81, 84) 300%);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.back-link:hover {
  background-color: var(--orange-color);
}

.slideshow-container {
  width: 50%;
  height: fit-content;
  margin: auto 20px;
  z-index: 2;
}

.project-info {
  width: 60%;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  color: var(--primary-blue);
  background-color: rgba(255, 255, 255, 0.3);
  position: relative;
  filter: opacity(0.8);
  z-index: -1;
}

.project-info::before {
  content: '';
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  filter: blur(20px);
  z-index: -1;
  border-radius: inherit;
}

.project-details {
  display: flex;
  flex-direction: column;
}

.project-details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project-details-row p {
  margin: 0 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.project-details-row p .fa-icon {
  margin-right: 10px;
}

.project-info h2 {
  margin-top: 20px;
  font-size: 1.5rem;
  border-bottom: 2px solid #ff6600;
  display: inline-block;
  padding-bottom: 5px;
}

.project-info ul {
  list-style-type: none;
  padding: 0;
}

.project-info ul li {
  margin: 5px 0;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.project-info ul li .fa-icon {
  margin-right: 10px;
}

.project-image-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.back-link:hover {
  background-color: #e65c00;
}

.remove-image-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.remove-image-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

.add-image {
  margin-top: 20px;
}

.add-image input {
  cursor: pointer;
  padding: 10px;
}

@media (max-width: 768px) {
  .project-content {
    flex-direction: column;
    align-items: center;
  }
  .slideshow-container, 
  .project-info {
    width: 90%;
    margin: 20px 0;
  }
  .project-title-back-link {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .project-titlee {
    width: 100%;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 10px;
}

.project-title-back-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.back-link {
  padding: 10px 20px;
  background-color: var(--primary-blue);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.back-link:hover {
  background-color: var(--orange-color);
}

.project-titlee {
  flex-grow: 1;
  text-align: center;
}

}