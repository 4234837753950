@keyframes gradientAnimation {
  0% {
    background-position: 10% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.subservice-header {
  background-image: radial-gradient(circle farthest-corner at 20% 20%, var(--primary-blue) -50%, rgb(158, 172, 190) 80.3%, rgb(195, 196, 197) 300%);
  background-size: 200% 200%;
  animation: gradientAnimation 4s ease-in-out infinite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
}

.subservice-container {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.subservice-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.subservice-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #cfd8dc;
  border-radius: 50%;
  color: var(--primary-blue);
  transition: height 0.5s ease-in-out;
}

.subservice-icon svg {
  width: 16px;
  height: 16px;
}

.subservice-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding: 0 15px;
}

.subservice-content.visible {
  max-height: 100%; 
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
}

.row.small .col {
  flex: 1 1 calc(50% - 10px);
  margin: 10px;
}

.row.large .col {
  flex: 1 1 calc(33.333% - 10px);
  margin: 10px;
}

@media (max-width: 768px) {
  .row.small .col, .row.large .col {
    flex: 1 1 calc(50% - 10px);
  }
  .subservice-icon svg {
    width: 10px;
    margin: 10px;
    height: 10px;
}
.subservice-title {
  font-size: 1rem;
}
.row{
  padding:0;
}
}

@media (max-width: 480px) {
  .row.small .col, .row.large .col {
    flex: 1 1 100%;
  }
  .subservice-icon svg {
    width: 10px;
    margin: 10px;
    height: 10px;
}
.subservice-title {
  font-size: 1rem;
}
.row{
  padding:0;
}
}
