.fullscreen-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden; 
  margin-bottom: 10px;
}

.navigation-container {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: rgba(254, 254, 254, 0.3);
  backdrop-filter: blur(5px);
  color: #ffffff !important;
  z-index: 10;
  padding: 0; 
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 70px;
  justify-content: center;
}

#dropdown-basic {
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
  /* border-bottom: var(--orange-color) !important; */
  font-size: 1.2rem !important;
  font: xx-large !important;
  position: relative;
  overflow: hidden;
}

#dropdown-basic::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--orange-color) !important;
  border-radius: 0 !important;
  transition: width 0.3s ease-in-out;
}

#dropdown-basic:hover::before {
  border-radius: 0 !important;
  width: 100%;
}

.drop-down {
  color: var(--primary-blue) !important;
  margin: auto 0 !important;
}

.logo-container img {
  display: block;
  height: auto;
  max-height: 100px;
  margin: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff !important;
  margin: 20px;
  cursor: pointer;
  font-size: 1.2rem !important;
  position: relative;
  overflow: hidden;
}

.nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--orange-color);
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::before {
  width: 100%;
}

.navigation-splide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.navigation-image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.navigation-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigation-image-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: rgba(70, 70, 70, 0.5);
}

.splide__arrow {
  background-color: rgba(255, 255, 255, 0.8) !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;
  z-index: 10 !important;
  border: 1px solid #fff;
}

.splide__arrow::before {
  color: #fff !important;
}

.splide__arrow:hover {
  background-color: #fff !important;
}

.splide__arrow--prev {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXAgPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXJpZ2h0Ij48cG9seWxpbmUgcG9pbnRzPSIxNSAxOCA5IDEyIDE1IDYiPjwvcG9seWxpbmU+PC9zdmc+'); 
}

.splide__arrow--next {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXAgPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXJpZ2h0Ij48cG9seWxpbmUgcG9pbnRzPSI5IDE4IDE1IDEyIDkgNiI+PC9wb2x5bGluZT48L3N2Zz4='); 
}

.navigation-title-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 3;
}

.navigation-line {
  font-size: 2rem;
  margin: 0.5rem 0;
}

.learn-more-btn {
  margin-top: 30px;
  padding: 10px 20px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.learn-more-btn:hover {
  background-color: #fff;
  color: #000;
}

.slide-down {
  animation: slide-down 1s forwards;
}

.slide-up {
  animation: slide-up 1s forwards;
}

@keyframes slide-down {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.navigation-pagination {
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  height: 12px;
  cursor: default;
  z-index: 2;
  text-align: center;
  justify-content: center;
}

.navigation-pagination .navigation-item {
  display: inline-block;
  padding: 15px 5px;
  position: relative;
  width: 46px;
  height: 32px;
  cursor: pointer;
  text-indent: -999em;
  z-index: 1;
}

.navigation-pagination .navigation-item+.navigation-item {
  margin-left: -2px;
}

.navigation-pagination .navigation-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 5px;
  width: 36px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  transition: background 0.2s ease;
}

.navigation-pagination .navigation-item::after {
  width: 0;
  background: #fff;
  z-index: 2;
  transition: width 0.2s ease;
}

.navigation-pagination .navigation-item:hover::before,
.navigation-pagination .navigation-item.is-active::before {
  background-color: #fff;
}

.content {
  position: relative;
  z-index: 5;
}
.navbar-offcanvas{
  color:var(--orange-color) !important;
}
@media (max-width: 768px) {
  .fullscreen-container {
    width: 100%;
  }

  .navigation-image-container {
    height: 100vh;
  }
  .navigation-title-overlay {
    font-size: 1rem;
  }
  .learn-more-btn {
    font-size: 0.8rem;
  }
  .nav-link{
    color: var(--primary-blue) !important;
    font-size: 1rem !important;
  }
}

.blue-link {
  color: var(--primary-blue) !important;
}
