.contactus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-image: radial-gradient(circle farthest-corner at 20% 90%, var(--primary-blue) -10%, rgb(220, 220, 220) 30.3%, rgb(159, 175, 255) 300%);
  width: 100%;
}

.contactus-container-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
}

.contactus-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
}

.contactus-info span {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-blue);
}

.contact-info-group {
  margin-top: 15px;
}

.contact-info-location {
  margin-bottom: 10px;
}

.contact-info-location span {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-blue);
}

.contact-info {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.contact-info svg {
  margin-right: 10px;
}

.contact-info a {
  color: var(--primary-blue);
  text-decoration: none;
  margin: 0 2px;
}

.contact-info a:hover {
  text-decoration: underline;
}

.details-container {
  display: flex;
  gap: 5px;
  height: 100%;
}

.details-container .image-container,
.details-container .form-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.details-container .image-container img,
.details-container .form-container form {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.map-container {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .contactus-container-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .details-container {
    flex-direction: column;
  }

  .details-container .image-container {
    display: none; /* Hide the image container */
  }

  .details-container .form-container {
    width: 100%;
    align-items: center;
  }

  .contactus-info {
    margin-bottom: 20px;
    font-size: small;
  }
  .contactus-container{
    width: 100%;
  }
}
