.shrinkLabel {
  top: -14px;
  font-size: 16px;
  color: #ffffff;
}

.form-input-label {
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 300ms ease all;
}

.form-input-label.shrink {
  top: -14px;
  font-size: 12px;
  color: #ffffff;
}

.form-input {
  background: none;
  background-color: var(--primary-blue);
  color: #ffffff;
  font-size: 18px;
  padding: 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ffffff;
  margin: 10px 0;
  resize: none; 
  overflow-y: auto;
}

.form-input:focus {
  outline: none;
}

.form-input:focus ~ .form-input-label {
  top: -14px;
  font-size: 12px;
  color: #ffffff;
}

.group {
  position: relative;
  margin: 5px;
}
